import axios, { AxiosResponse } from 'axios'
import { useAuthTokenInterceptor, setAuthTokens, TokenRefreshRequest } from 'axios-jwt'
import {
  ResultsType,
  CompaniesType,
  CurrentAttemptsType,
  CurrentDataDetailType,
  CurrentTimerType,
  ParticipantsAttemptsAnswersType,
  ProfileType,
  RegistrationApplicationType,
  TestQestionsType,
  TestsType,
  TimerStateType,
} from '../utils/types'

const instance = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
  responseType: `json`,
})

const ssoTokenEndpoint = `${process.env.REACT_APP_ENDPOINT ?? ''}/api/jwt/sso/`
const createTokenEndpoint = `${process.env.REACT_APP_ENDPOINT ?? ''}/api/jwt/create/`
const refreshTokenEndpoint = `${process.env.REACT_APP_ENDPOINT ?? ''}/api/jwt/refresh/`

const requestRefresh: TokenRefreshRequest = async (refreshToken: string): Promise<string> => {
  const response = await axios.post(refreshTokenEndpoint, { refresh: refreshToken })
  return response.data.access
}

const authTokenInterceptorConfig = {
  header: 'Authorization',
  headerPrefix: 'Bearer ',
  credential: 'include',
  requestRefresh,
}

useAuthTokenInterceptor(instance, authTokenInterceptorConfig)

const REGISTER = '/api/register'
const USERS = '/api/users'
const SURVEY_TIMER = '/api/pass-test'
const SURVEY_PERSONAL = '/api/personal-profile'
const SURVEY_TEST = '/api/test'
const SURVEY_QUESTIONS = '/api/test-questions'
const SURVEY_PASSED = '/api/pass-test-answers'
const COMPANIES = '/api/companies'

type AuthResponse = {
  access: string
  refresh: string
}

const auth = {
  registration: (payload: RegistrationApplicationType): Promise<AxiosResponse> =>
    instance.post(REGISTER + '/', payload),
  requestSso: async (): Promise<AuthResponse | undefined> => {
    try {
      return (await axios.get(ssoTokenEndpoint)).data
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        return undefined
      }
      throw err
    }
  },
  createJwt: async (data: RegistrationApplicationType): Promise<AuthResponse | undefined> => {
    try {
      return (await axios.post(createTokenEndpoint, data)).data
    } catch (err) {
      if (err.response.status === 400) {
        return undefined
      }
      throw err
    }
  },
  setupToken: (tokens: AuthResponse): void => {
    setAuthTokens({ accessToken: tokens.access, refreshToken: tokens.refresh })
  },
  getProfile: async (): Promise<AxiosResponse<ProfileType>> => instance.get(`${USERS}/me/`),
}

export const survey = {
  getTimer: (userId: number, attemptId: number): Promise<AxiosResponse<CurrentTimerType>> =>
    instance.get(`${SURVEY_TIMER}/${userId}/${attemptId}/timer/`),

  runTimer: (userId: number, attemptId: number): Promise<AxiosResponse<TimerStateType>> =>
    instance.put(`${SURVEY_TIMER}/run-timer/${userId}/${attemptId}/`, { timerState: 2 }),

  stopTimer: (userId: number, attemptId: number): Promise<AxiosResponse<TimerStateType>> =>
    instance.put(`${SURVEY_TIMER}/stop-timer/${userId}/${attemptId}/`, { timerState: 1 }),

  getTestsForUser: (userId: number): Promise<AxiosResponse<TestsType>> =>
    instance.get(`${SURVEY_PERSONAL}/tests/${userId}/`),

  getUserAttempts: (userId: number): Promise<AxiosResponse<CurrentAttemptsType>> =>
    instance.get(`${SURVEY_PERSONAL}/attempts-history/${userId}/`),

  getQuestions: (surveyId: number): Promise<AxiosResponse<TestQestionsType>> =>
    instance.get(`${SURVEY_QUESTIONS}/${surveyId}/question/`),

  getUserAnswers: (
    userId: number,
    surveyId: number,
    attemptId: number,
  ): Promise<AxiosResponse<ParticipantsAttemptsAnswersType>> =>
    instance.get(`${SURVEY_PASSED}/${userId}/${surveyId}/${attemptId}/participant-answer/`),

  getTestDetail: (userId: number, surveyId: number): Promise<AxiosResponse<CurrentDataDetailType>> =>
    instance.get(`${SURVEY_TEST}/${userId}/${surveyId}/show/`),

  sendTestAnswers: (resultObj: ResultsType, userId: number, attemptId: number): Promise<AxiosResponse<void>> =>
    instance.post(`${SURVEY_PASSED}/${userId}/${attemptId}/participant-answer/`, resultObj),
}

const companies = {
  getCompanies: (): Promise<AxiosResponse<CompaniesType>> => instance.get(`${COMPANIES}/`),
}

export default {
  auth,
  survey,
  companies,
}
