import { Layout } from 'antd'
import 'antd/dist/antd.min.css'
import React, { FC } from 'react'
import Footer from '../Footer'
import Header from '../Header'
import { useApp } from './useApp'

const App: FC = (props) => {
  const { children } = props
  const { checked } = useApp()

  return (
    <>
      <Layout>
        <Layout.Header role={'heading'}>
          <Header />
        </Layout.Header>
        <Layout>
          <Layout.Content>{checked && children}</Layout.Content>
        </Layout>
      </Layout>
      <Layout.Footer>
        <Footer />
      </Layout.Footer>
    </>
  )
}

export default App
