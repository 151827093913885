import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { RootState } from '../../store/store'

const PrivateRoute: FC<PrivateRouteProps> = (props) => {
  const { user } = useSelector((state: RootState) => state.account)

  if (user) return <Route {...props} />
  return <Redirect to={'/auth'} />
}

export default PrivateRoute

type PrivateRouteProps = RouteProps
