import React, { FC } from 'react'
import styles from './footer.module.scss'

const Footer: FC = () => {
  return (
    <div className={styles.footer}>
      <a href="https://digitalatom.ru" target="_blank" rel="noopener noreferrer" className={styles.digitalatom}>
        сделано в <span>digital atom_</span>
      </a>
    </div>
  )
}

export default Footer
