import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { isLoggedIn } from 'axios-jwt'
import appAPI from '../../api/api'
import { AccountStateType, RegistrationApplicationType } from '../../utils/types'

export const initialUserState: AccountStateType = {
  user: undefined,
  loading: false,
  expired: false,
}

export const fetchAuthSso = createAsyncThunk('fetchAuthSso', async (_, { rejectWithValue }) => {
  if (isLoggedIn()) {
    return (await appAPI.auth.getProfile()).data
  }

  try {
    const tokens = await appAPI.auth.requestSso()

    if (!tokens) {
      return undefined
    }

    appAPI.auth.setupToken(tokens)
    return (await appAPI.auth.getProfile()).data
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

export const fetchAuth = createAsyncThunk(
  'fetchLogin',
  async (payload: RegistrationApplicationType, { rejectWithValue }) => {
    try {
      let tokens = await appAPI.auth.createJwt(payload)

      if (!tokens) {
        await appAPI.auth.registration(payload)
        tokens = await appAPI.auth.createJwt(payload)
      }

      if (!tokens) {
        return undefined
      }

      appAPI.auth.setupToken(tokens)
      return (await appAPI.auth.getProfile()).data
    } catch (err: any) {
      return rejectWithValue({ messages: err.response.data, status: err.response.status })
    }
  },
)

const authSlice = createSlice({
  name: 'account',
  initialState: initialUserState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // -------------------- авторизация в КСПД, получение пользователя
      .addCase(fetchAuthSso.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchAuthSso.fulfilled, (state, { payload }) => {
        state.loading = false
        state.expired = false
        state.user = payload
      })
      .addCase(fetchAuthSso.rejected, (state) => {
        state.loading = false
      })

      // -------------------- авторизация через форму, получение пользователя
      .addCase(fetchAuth.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchAuth.fulfilled, (state, { payload }) => {
        state.loading = false
        state.expired = false
        state.user = payload
      })
      .addCase(fetchAuth.rejected, (state) => {
        state.loading = false
      })

      // -------------------- что делать если токен протух
      .addMatcher(
        (action): action is PayloadAction<AxiosResponse | undefined> => action.type.endsWith('/rejected'),
        (state, action) => {
          const payload = action.payload
          if (!payload) return

          if (payload.status === 401) {
            state.user = undefined
            state.expired = true
          }
        },
      )
  },
})

export const accountActions = {
  ...authSlice.actions,
  fetchAuthSso,
  fetchAuth,
}

export default authSlice.reducer
