import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import Logo from '../Logo'
import styles from './header.module.scss'

const Header: FC = () => {
  const user = useSelector((state: RootState) => state.account.user)

  return (
    <div className={styles.content}>
      <a href="https://coportal.rosenergoatom.ru/" className={styles.logoWrapper}>
        <Logo />
        <div className={styles.logoText}>
          <p className={styles.logoRosenergoatom} style={{ fontSize: 9 }}>
            Росэнергоатом
          </p>
          <p className={styles.logoRosatom} style={{ fontSize: 7 }}>
            Росатом
          </p>
        </div>
      </a>
      {user && (
        <p className={styles.nameUser}>
          {user.lastName} {user.firstName[0]}.{user.middleName[0]}.
        </p>
      )}
    </div>
  )
}

export default Header
