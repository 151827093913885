import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { accountActions } from '../../store/slices/authSlice'
import { RootState } from '../../store/store'

export const useApp = () => {
  const dispatch = useDispatch()
  const { expired } = useSelector((state: RootState) => state.account)

  const [checked, setChecked] = useState(false)

  useEffect(() => {
    const checkAuth = async () => {
      await dispatch(accountActions.fetchAuthSso())
      setChecked(true)
    }

    checkAuth()
  }, [dispatch])

  useEffect(() => {
    const relogin = async () => {
      setChecked(false)
      await dispatch(accountActions.fetchAuthSso())
      setChecked(true)
    }

    if (expired) {
      relogin()
    }
  }, [dispatch, expired])

  return { checked }
}
