import { combineReducers } from 'redux'
import authSlice from './slices/authSlice'
import companiesSlice from './slices/companiesSlice'
import surveySlice from './slices/surveySlice'

const rootReducer = combineReducers({
  account: authSlice,
  survey: surveySlice,
  companies: companiesSlice,
})

export default rootReducer
