import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import {
  AnswerType,
  CurrentDataDetailType,
  CurrentTimerType,
  FetchDetailPayloadType,
  QuestionType,
  SendAnswersPayloadType,
  SurveyStateType,
} from '../../utils/types'
import { declineWord } from '../../utils/declineWord'

const initialSurveyState: SurveyStateType = {
  loading: false,
  loadingAnswers: false,
  tests: [],
  attempts: [],
  answers: [],
  timer: undefined,
  surveyDetail: {} as CurrentDataDetailType,
}

const fetchTestsList = createAsyncThunk('/fetchTestsList', async (userId: number) =>
  appAPI.survey.getTestsForUser(userId),
)

const fetchAttempts = createAsyncThunk('/fetchAttempts', async (userId: number) =>
  appAPI.survey.getUserAttempts(userId),
)

const fetchUserAnswers = createAsyncThunk(
  '/fetchUserAnswers',
  async ({ userId, surveyId, attemptId }: FetchDetailPayloadType) => {
    const answers = (await appAPI.survey.getUserAnswers(userId, surveyId, attemptId)).data.results
    const questions = (await appAPI.survey.getQuestions(surveyId)).data.results
    return { answers, questions }
  },
)

const fetchTestDetail = createAsyncThunk(
  '/fetchTestDetail',
  async ({ userId, attemptId, surveyId, notStartTimer }: FetchDetailPayloadType) => {
    let resultTimer

    if (!notStartTimer) {
      resultTimer = (await appAPI.survey.getTimer(userId, attemptId)).data

      if (!resultTimer || resultTimer.detail)
        return { result: {} as CurrentDataDetailType, resultTimer: {} as CurrentTimerType }

      if (resultTimer.statusStart || resultTimer.timerState === 1) {
        await appAPI.survey.runTimer(userId, attemptId)
        resultTimer = (await appAPI.survey.getTimer(userId, attemptId)).data
      }
    }

    const result = await (await appAPI.survey.getTestDetail(userId, surveyId)).data

    return { result, resultTimer }
  },
)

const sendTestAnswers = createAsyncThunk(
  '/sendAnswers',
  async ({ userId, attemptId, options, survey, resultsArray }: SendAnswersPayloadType) => {
    try {
      await appAPI.survey.sendTestAnswers(resultsArray, userId, attemptId)
      await appAPI.survey.stopTimer(userId, attemptId)
      return { options, survey }
    } catch (err) {
      survey.completedHtml = '<h3>Произошла ошибка, мы не можем сохранить результаты</h3>'
      options.showDataSavingError()
      throw err
    }
  },
)

const surveySlice = createSlice({
  name: 'survey',
  initialState: initialSurveyState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // -------------------- получение списка тестов
      .addCase(fetchTestsList.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchTestsList.fulfilled, (state, { payload: { data } }) => {
        const tests = data.map((test) => {
          if (Object.keys(test.userAttempt).length === 0) {
            test.userAttempt = {
              timer: 0,
              currentAttempt: 0,
              dateStarted: 0,
              testPeriodTimer: 0,
            }
          }
          // сколько времени осталось до запуска тестирования
          if (typeof test.dateToStart === 'string') {
            const moreThenDay = test.dateToStart.includes(' ')
            if (moreThenDay) {
              const days = test.dateToStart.split(' ')[0]
              const declineDay = declineWord(Number(days), ['день', 'дня', 'дней'])
              const time = test.dateToStart.split(',')[1].split('.')[0]
              test.dateToStart = `${days} ${declineDay} ${time}`
            } else {
              const time = test.dateToStart.split('.')[0]
              test.dateToStart = time
            }
          }
          // сколько осталось попыток
          const remainAttempt = test.rule.maxAttempts - test.userAttempt.currentAttempt
          test.remainAttempts = remainAttempt

          // проходит ли сейчас тестирование, идёт ли сейчас интервал между тестированиями
          const testNotStarted = remainAttempt > 0 && test.dateToStart === 0 && test.userAttempt.testPeriodTimer === 0
          const noAttempts =
            remainAttempt === 0 &&
            test.dateToStart === 0 &&
            test.userAttempt.testPeriodTimer === 0 &&
            test.userAttempt.timer !== 0
          const testPaused = test.userAttempt.testPeriodTimer !== 0
          if (testNotStarted || noAttempts) {
            test.active = 1
          } else if (testPaused) {
            test.userAttempt.testPeriodTimer = test.userAttempt.testPeriodTimer.toString().split('.')[0]
          } else {
            test.active = 0
          }
          // сколько времени из тестирования прошло
          const timerNotStarted = test.userAttempt.timer === 0 || test.userAttempt.timer === '0:00:00'
          const timerStarted =
            test.userAttempt.timer &&
            typeof test.userAttempt.timer === 'string' &&
            Number(test.userAttempt.timer) !== test.test.duration
          if (timerNotStarted) {
            test.userAttempt.timer = test.test.duration
          } else if (timerStarted) {
            const time = test.userAttempt.timer!.toString().split('.')[0].split(':')
            const timeMinutes = Number(time[0]) * 60 + Number(time[1])
            test.userAttempt.timer = timeMinutes
          }
          return test
        })
        state.tests = tests
        state.loading = false
      })
      .addCase(fetchTestsList.rejected, (state) => {
        state.loading = false
      })

      // -------------------- получение результатов тестирований
      .addCase(fetchAttempts.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchAttempts.fulfilled, (state, { payload: { data } }) => {
        const attempt = data.map((item) => {
          item.fullAttempts = []
          let currentAttempt = 0
          if (item.userAttempt.length) {
            const participantAttempts = item.userAttempt.map((attempt) => attempt.participantsAttempts).flat()
            item.fullAttempts.push(participantAttempts)
            participantAttempts.forEach((attempt) => {
              currentAttempt++
              const date = new Date(attempt.dateCreated).toLocaleDateString('ru')
              attempt.testId = item.test.id
              attempt.testTitle = item.test.title
              attempt.successPercent = item.test.successPercent ?? 0
              attempt.maxPoints = item.testMaxPoints
              attempt.date = date
              attempt.currentAttempt = currentAttempt
            })
          }
          return item
        })
        state.attempts = attempt
        state.loading = false
      })
      .addCase(fetchAttempts.rejected, (state) => {
        state.loading = false
      })

      // -------------------- получение детальных результатов конкретной попытки
      .addCase(fetchUserAnswers.pending, (state) => {
        state.answers = []
        state.loadingAnswers = true
      })
      .addCase(fetchUserAnswers.fulfilled, (state, { payload: { answers, questions } }) => {
        const questionsAndAnswers = questions.map((currentQuestion) => {
          const questionAndAnswer = { question: '', textAnswer: '', trueAnswer: '', isCorrect: 'Нет ответа' }
          if (!answers) {
            return questionAndAnswer
          }
          questionAndAnswer.question =
            currentQuestion.text!.indexOf(' <img ') !== -1
              ? currentQuestion.text!.slice(0, currentQuestion.text!.indexOf(' <img '))
              : currentQuestion.text!
          const answersToСurrentQuestion = answers.find((answer) => answer.question === currentQuestion.id)
          if (!answersToСurrentQuestion) {
            return questionAndAnswer
          }
          questionAndAnswer.textAnswer =
            typeof answersToСurrentQuestion.answer === 'string'
              ? answersToСurrentQuestion.answer
              : answersToСurrentQuestion.answer.join(', ')
          if (currentQuestion.type === 'range') {
            questionAndAnswer.trueAnswer = `От ${answersToСurrentQuestion.trueAnswer.join(', ').split(';')[0]} до ${
              answersToСurrentQuestion.trueAnswer.join(', ').split(';')[1]
            }`
          } else if (currentQuestion.type === 'ranking') {
            questionAndAnswer.trueAnswer = answersToСurrentQuestion.trueAnswer.flat().join(', ')
          } else {
            questionAndAnswer.trueAnswer = answersToСurrentQuestion.trueAnswer.join(', ')
          }
          questionAndAnswer.isCorrect = answersToСurrentQuestion.isCorrect
          return questionAndAnswer
        })

        state.answers = questionsAndAnswers
        state.loadingAnswers = false
      })
      .addCase(fetchUserAnswers.rejected, (state) => {
        state.loadingAnswers = false
      })

      // -------------------- получение данных тестирования
      .addCase(fetchTestDetail.pending, (state) => {
        state.timer = undefined
        state.loading = true
      })
      .addCase(fetchTestDetail.fulfilled, (state, { payload: { result, resultTimer } }) => {
        if (result.status || result.active || result.error) {
          state.surveyDetail = result
          state.timer = resultTimer
        }
        const questions = (result.questions ?? []).map((item: QuestionType) => {
          const { text, ...rest } = item
          const question = {
            ...rest,
            name: text,
            choices: item.answers.map((answer: AnswerType) => answer.answer),
          }
          if (item.type === 'range') {
            question.validators = [
              {
                type: 'regex',
                text: 'Требуется ввести корректное цифровое значение',
                regex: '^\\d*([\\.\\,]\\d+)?$',
              },
            ]
            question.type = 'text'
          }
          return question
        })
        state.surveyDetail = { ...result, questions }
        state.timer = resultTimer
        state.loading = false
      })
      .addCase(fetchTestDetail.rejected, (state) => {
        state.loading = false
      })

      // -------------------- отправка ответов
      .addCase(sendTestAnswers.pending, (state) => {
        state.loading = true
      })
      .addCase(sendTestAnswers.fulfilled, (state, { payload: { options } }) => {
        options.showDataSavingSuccess()
        state.loading = false
      })
      .addCase(sendTestAnswers.rejected, (state) => {
        state.loading = false
      })
  },
})

export default surveySlice.reducer

export const surveySliceActions = {
  ...surveySlice.actions,
  fetchTestsList,
  fetchAttempts,
  fetchUserAnswers,
  fetchTestDetail,
  sendTestAnswers,
}
