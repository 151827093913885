import React, { FC } from 'react'

const Logo: FC = () => {
  return (
    <svg width="38" height="38" viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="159" y="158" width="85" height="85">
        <path
          d="M170.665 172.126C155.184 189.338 156.461 215.898 173.628 231.425C190.796 247.003 217.263 245.675 232.795 228.514C248.328 211.301 246.999 184.742 229.832 169.215C221.81 161.911 211.745 158.335 201.73 158.335C190.285 158.335 178.942 162.983 170.665 172.126Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0)">
        <path d="M248.328 158.284H155.132V247.054H248.328V158.284Z" fill="#FF0000" />
        <path d="M248.328 158.284H155.132V247.054H248.328V158.284Z" fill="url(#paint0_radial)" />
      </g>
      <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="81" y="0" width="275" height="110">
        <path
          d="M81.71 39.8392C143.381 9.9598 186.095 30.5944 212.613 56.6431C216.138 60.1163 219.357 63.6405 222.321 67.1648C239.182 87.3397 247.05 107.208 247.05 107.208C257.831 109.2 267.846 109.762 277.196 109.302C277.809 109.251 278.371 109.251 278.984 109.2C279.546 109.149 280.057 109.098 280.619 109.047C301.363 107.157 328.29 99.2404 352.917 74.5708C353.786 73.7535 354.603 72.8342 355.421 71.9148C351.589 67.3691 347.654 62.8744 343.414 58.584C304.224 19.511 252.926 0 201.679 0C159.526 0 117.271 13.2797 81.71 39.8392Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask1)">
        <path d="M119.894 -94.8453L43.655 94.4297L317.265 204.56L393.504 15.2853L119.894 -94.8453Z" fill="#FF0000" />
        <path
          d="M119.894 -94.8453L43.655 94.4297L317.265 204.56L393.504 15.2853L119.894 -94.8453Z"
          fill="url(#paint1_linear)"
        />
      </g>
      <mask id="mask2" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="129" width="122" height="254">
        <path
          d="M6.80645 146.077C-19.1493 243.632 31.2805 343.435 121.461 382.099C64.747 343.639 61.2216 296.343 70.5718 260.334C71.798 255.584 73.2798 250.987 74.8126 246.697C83.9073 222.027 97.1406 205.274 97.1406 205.274C93.513 194.957 88.9145 186.019 83.8562 178.102C83.4986 177.591 83.2431 177.131 82.8854 176.57C82.5789 176.11 82.2723 175.701 81.9146 175.242C69.9076 158.233 49.5721 138.876 15.9012 129.886C14.726 129.58 13.5509 129.273 12.3757 129.018C10.3319 134.636 8.44145 140.255 6.80645 146.077Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask2)">
        <path d="M118.685 109.524L-54.3265 134.032L-16.3908 401.649L156.621 377.142L118.685 109.524Z" fill="#FF0000" />
        <path
          d="M118.685 109.524L-54.3265 134.032L-16.3908 401.649L156.621 377.142L118.685 109.524Z"
          fill="url(#paint2_linear)"
        />
      </g>
      <mask id="mask3" mask-type="alpha" maskUnits="userSpaceOnUse" x="228" y="176" width="172" height="222">
        <path
          d="M318.582 281.734C313.83 283.062 309.18 284.084 304.633 284.901C278.728 289.396 257.575 286.331 257.575 286.331C250.473 294.657 244.955 303.084 240.663 311.409C240.408 311.971 240.101 312.431 239.846 312.993C239.59 313.504 239.386 313.963 239.182 314.474C230.445 333.372 223.853 360.647 232.897 394.306C233.204 395.429 233.561 396.655 233.919 397.83C239.744 396.808 245.619 395.634 251.444 394.101C348.983 367.797 410.193 274.277 398.595 176.825C393.639 245.113 354.45 271.775 318.582 281.734Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask3)">
        <path d="M339.405 86.3036L116.551 260.777L294.741 488.216L517.595 313.743L339.405 86.3036Z" fill="#FF0000" />
        <path
          d="M339.405 86.3036L116.551 260.777L294.741 488.216L517.595 313.743L339.405 86.3036Z"
          fill="url(#paint3_linear)"
        />
      </g>
      <mask id="mask4" mask-type="alpha" maskUnits="userSpaceOnUse" x="15" y="23" width="198" height="180">
        <path
          d="M81.7102 39.8388C81.7102 39.8388 38.1781 67.1133 15.4413 121.816C21.4193 122.837 82.1189 135.095 104.754 202.566C110.272 199.042 127.235 185.609 138.68 148.681C152.475 103.99 178.687 71.0461 212.664 56.6938C212.664 56.6938 189.467 23.6988 142.41 23.6988C125.344 23.6477 105.111 27.9892 81.7102 39.8388Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask4)">
        <path d="M74.2417 -66.5507L-66.4166 149.203L153.866 292.712L294.524 76.958L74.2417 -66.5507Z" fill="#FF0000" />
        <path
          d="M74.2417 -66.5507L-66.4166 149.203L153.866 292.712L294.524 76.958L74.2417 -66.5507Z"
          fill="url(#paint4_linear)"
        />
      </g>
      <mask id="mask5" mask-type="alpha" maskUnits="userSpaceOnUse" x="64" y="260" width="188" height="140">
        <path
          d="M121.462 382.098C121.462 382.098 166.782 406.155 225.489 398.494C224.416 395.582 219.664 381.332 219.664 361.413C219.664 338.786 225.949 308.805 251.087 280.56L251.343 280.151C247.204 275.758 232.847 264.93 195.855 273.358C150.279 283.777 99.9512 282.552 70.5211 260.334C70.5722 260.385 38.9961 328.264 121.462 382.098Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask5)">
        <path d="M249.189 238.923L24.2739 261.841L41.1689 427.533L266.084 404.616L249.189 238.923Z" fill="#FF0000" />
        <path
          d="M249.189 238.923L24.2739 261.841L41.1689 427.533L266.084 404.616L249.189 238.923Z"
          fill="url(#paint5_linear)"
        />
      </g>
      <mask id="mask6" mask-type="alpha" maskUnits="userSpaceOnUse" x="244" y="78" width="155" height="204">
        <path
          d="M271.985 118.649C263.707 118.649 254.919 117.832 245.62 115.891L245.16 115.789C243.474 122.02 242.044 139.642 267.182 166.712C298.962 200.984 323.079 245.164 318.531 281.735C318.531 281.735 393.18 275.146 398.596 176.774C398.596 176.774 396.756 125.494 360.735 78.4526C354.297 86.3183 323.436 118.598 271.985 118.649Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask6)">
        <path d="M392.687 48.623L203.341 86.116L248.005 311.517L437.351 274.024L392.687 48.623Z" fill="#FF0000" />
        <path
          d="M392.687 48.623L203.341 86.116L248.005 311.517L437.351 274.024L392.687 48.623Z"
          fill="url(#paint6_linear)"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(188.353 176.945) rotate(0.0556034) scale(58.9461 59.0963)"
        >
          <stop stopColor="#4495D1" />
          <stop offset="0.4224" stopColor="#2E5FA1" />
          <stop offset="0.8049" stopColor="#1C357B" />
          <stop offset="1" stopColor="#15256D" />
        </radialGradient>
        <linearGradient
          id="paint1_linear"
          x1="172.64"
          y1="85.6219"
          x2="239.887"
          y2="-9.21634"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4495D1" />
          <stop offset="0.4224" stopColor="#2E5FA1" />
          <stop offset="0.8049" stopColor="#1C357B" />
          <stop offset="1" stopColor="#15256D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="72.3295"
          y1="333.176"
          x2="20.8897"
          y2="165.503"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4495D1" />
          <stop offset="0.4224" stopColor="#2E5FA1" />
          <stop offset="0.8049" stopColor="#1C357B" />
          <stop offset="1" stopColor="#15256D" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="330.95"
          y1="229.029"
          x2="297.43"
          y2="359.862"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4495D1" />
          <stop offset="0.4224" stopColor="#2E5FA1" />
          <stop offset="0.8049" stopColor="#1C357B" />
          <stop offset="1" stopColor="#15256D" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="129.565"
          y1="147.177"
          x2="88.1176"
          y2="50.2803"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4495D1" />
          <stop offset="0.4224" stopColor="#2E5FA1" />
          <stop offset="0.8049" stopColor="#1C357B" />
          <stop offset="1" stopColor="#15256D" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="217.702"
          y1="318.696"
          x2="87.0789"
          y2="346.424"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4495D1" />
          <stop offset="0.4224" stopColor="#2E5FA1" />
          <stop offset="0.8049" stopColor="#1C357B" />
          <stop offset="1" stopColor="#15256D" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="299.722"
          y1="128.503"
          x2="350.647"
          y2="248.167"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4495D1" />
          <stop offset="0.4224" stopColor="#2E5FA1" />
          <stop offset="0.8049" stopColor="#1C357B" />
          <stop offset="1" stopColor="#15256D" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Logo
