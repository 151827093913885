import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import appAPI from '../../api/api'
import { CompaniesStateType } from '../../utils/types'

export const initialCompaniesState: CompaniesStateType = {
  companies: [],
  loading: false,
}

const fetchAllCompanies = createAsyncThunk('fetchAllCompanies', async (_, { rejectWithValue }) => {
  try {
    return await appAPI.companies.getCompanies()
  } catch (err: any) {
    return rejectWithValue({ messages: err.response.data, status: err.response.status })
  }
})

const companiesSlice = createSlice({
  name: 'companies',
  initialState: initialCompaniesState,
  reducers: {},
  extraReducers: (builder) => {
    // -------------------- получение списка организаций
    builder.addCase(fetchAllCompanies.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchAllCompanies.fulfilled, (state, { payload }) => {
      state.companies = payload.data
      state.loading = false
    })
    builder.addCase(fetchAllCompanies.rejected, (state) => {
      state.loading = false
    })
  },
})

export const companiesActions = {
  ...companiesSlice.actions,
  fetchAllCompanies,
}

export default companiesSlice.reducer
