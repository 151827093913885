import React, { FC } from 'react'
import styles from './pages.module.scss'
import { Link } from 'react-router-dom'
import { CONFIDENTIALITY_POLICY } from '../constants/routes'

const ConfidentialityPolicyPage: FC = () => {
  return (
    <section className={`${styles.page} ${styles.confPolPage}`}>
      <h3 className={styles.confPolTitle}>Политика в отношении обработки персональных данных</h3>
      <h5 className={styles.confPolSubtitle}>1. Общие положения</h5>
      <p className={styles.confPolText}>
        Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от
        27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок обработки персональных данных и меры по
        обеспечению безопасности персональных данных, предпринимаемые АО «КОНСИСТ-ОС» (далее – Оператор).
      </p>
      <p className={styles.confPolText}>
        1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод
        человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность
        частной жизни, личную и семейную тайну.
      </p>
      <p className={styles.confPolText}>
        1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко
        всей информации, которую Оператор может получить о посетителях веб-сайта &nbsp;
        <Link to={'/'}>https://portal-survey.rosenergoatom.ru</Link>.
      </p>
      <h5 className={styles.confPolSubtitle}>2. Основные понятия, используемые в Политике</h5>
      <p className={styles.confPolText}>
        2.1. Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств
        вычислительной техники;
      </p>
      <p className={styles.confPolText}>
        2.2. Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением
        случаев, если обработка необходима для уточнения персональных данных);
      </p>
      <p className={styles.confPolText}>
        2.3. Веб-сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных,
        обеспечивающих их доступность в сети интернет по сетевому адресу &nbsp;
        <Link to={'/'}>https://portal-survey.rosenergoatom.ru</Link>;
      </p>
      <p className={styles.confPolText}>
        2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных,
        и обеспечивающих их обработку информационных технологий и технических средств;
      </p>
      <p className={styles.confPolText}>
        2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования
        дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту
        персональных данных;
      </p>
      <p className={styles.confPolText}>
        2.6. Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых
        с использованием средств автоматизации или без использования таких средств с персональными данными, включая
        сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
        использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление,
        уничтожение персональных данных;
      </p>
      <p className={styles.confPolText}>
        2.7. Оператор – государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или
        совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также
        определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия
        (операции), совершаемые с персональными данными;
      </p>
      <p className={styles.confPolText}>
        2.8. Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому
        Пользователю веб-сайта &nbsp;
        <Link to={'/'}>https://portal-survey.rosenergoatom.ru</Link>;
      </p>
      <p className={styles.confPolText}>
        2.9. Пользователь – любой посетитель веб-сайта &nbsp;
        <Link to={'/'}>https://portal-survey.rosenergoatom.ru</Link>;
      </p>
      <p className={styles.confPolText}>
        2.10. Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному
        лицу или определенному кругу лиц;
      </p>
      <p className={styles.confPolText}>
        2.11. Распространение персональных данных – любые действия, направленные на раскрытие персональных данных
        неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными
        неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации,
        размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным
        каким-либо иным способом;
      </p>
      <p className={styles.confPolText}>
        2.12. Трансграничная передача персональных данных – передача персональных данных на территорию иностранного
        государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу;
      </p>
      <p className={styles.confPolText}>
        2.13. Уничтожение персональных данных – любые действия, в результате которых персональные данные уничтожаются
        безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе
        персональных данных и (или) уничтожаются материальные носители персональных данных.
      </p>
      <h5 className={styles.confPolSubtitle}>
        3. Оператор может обрабатывать следующие персональные данные Пользователя
      </h5>
      <p className={styles.confPolText}>3.1. Фамилия, имя, отчество;</p>
      <p className={styles.confPolText}>3.2. Электронный адрес;</p>
      <p className={styles.confPolText}>3.3. Номера телефонов;</p>
      <p className={styles.confPolText}>3.4. Год, месяц, дата и место рождения;</p>
      <p className={styles.confPolText}>3.5. Наименование организации, от имени которой выступает Пользователь;</p>
      <p className={styles.confPolText}>
        3.6. Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов «cookie») с
        помощью сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).
      </p>
      <p className={styles.confPolText}>
        3.7. Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.
      </p>
      <h5 className={styles.confPolSubtitle}>4. Цели обработки персональных данных</h5>
      <p className={styles.confPolText}>
        4.1. Цель обработки персональных данных Пользователя — информирование Пользователя посредством отправки
        электронных писем; предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на
        веб-сайте.
      </p>
      <p className={styles.confPolText}>
        4.2. Также Оператор имеет право направлять Пользователю уведомления о предстоящих чемпионатах, новостных блоках
        и событиях, продуктах и услугах. Пользователь всегда может отказаться от получения информационных сообщений,
        направив Оператору письмо на адрес электронной почты &nbsp;
        <Link
          to="#"
          onClick={(e) => {
            window.location.href = 'mailto: digitalatom-service@rosenergoatom.ru'
            e.preventDefault()
          }}
        >
          digitalatom-service@rosenergoatom.ru
        </Link>
        &nbsp; с пометкой «Отказ от уведомлений информационного портала».
      </p>
      <p className={styles.confPolText}>
        4.3. Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора
        информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.
      </p>
      <h5 className={styles.confPolSubtitle}>5. Правовые основания обработки персональных данных</h5>
      <p className={styles.confPolText}>
        5.1. Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки
        Пользователем самостоятельно через специальные формы, расположенные на сайте &nbsp;
        <Link to={'/'}>https://portal-survey.rosenergoatom.ru</Link>. Заполняя соответствующие формы и/или отправляя
        свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.
      </p>
      <p className={styles.confPolText}>
        5.2. Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера
        Пользователя (включено сохранение файлов «cookie» и использование технологии JavaScript).
      </p>
      <h5 className={styles.confPolSubtitle}>
        6. Порядок сбора, хранения, передачи и других видов обработки персональных данных
      </h5>
      <p className={styles.confPolText}>
        Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых,
        организационных и технических мер, необходимых для выполнения в полном объеме требований действующего
        законодательства в области защиты персональных данных.
      </p>
      <p className={styles.confPolText}>
        6.1. Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к
        персональным данным неуполномоченных лиц.
      </p>
      <p className={styles.confPolText}>
        6.2. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за
        исключением случаев, связанных с исполнением действующего законодательства.
      </p>
      <p className={styles.confPolText}>
        6.3. В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно,
        путем направления Оператору уведомление на адрес электронной почты Оператора
        digitalatom-service@rosenergoatom.ru с пометкой «Актуализация персональных данных».
      </p>
      <p className={styles.confPolText}>
        6.4. Срок обработки персональных данных является неограниченным. Пользователь может в любой момент отозвать свое
        согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на
        электронный адрес Оператора digitalatom-service@rosenergoatom.ru с пометкой «Отзыв согласия на обработку
        персональных данных».
      </p>
      <h5 className={styles.confPolSubtitle}>7. Трансграничная передача персональных данных</h5>
      <p className={styles.confPolText}>
        7.1. Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в том, что
        иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных,
        обеспечивается надежная защита прав субъектов персональных данных.
      </p>
      <p className={styles.confPolText}>
        7.2. Трансграничная передача персональных данных на территории иностранных государств, не отвечающих
        вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме субъекта
        персональных данных на трансграничную передачу его персональных данных и/или исполнения договора, стороной
        которого является субъект персональных данных.
      </p>
      <h5 className={styles.confPolSubtitle}>8. Заключительные положения</h5>
      <p className={styles.confPolText}>
        8.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его
        персональных данных, обратившись к Оператору с помощью электронной почты digitalatom-service@rosenergoatom.ru.
      </p>
      <p className={styles.confPolText}>
        8.2. В данном документе будут отражены любые изменения политики обработки персональных данных Оператором.
        Политика действует бессрочно до замены ее новой версией. 8.3. Актуальная версия Политики в свободном доступе
        расположена в сети Интернет по адресу &nbsp;
        <Link to={CONFIDENTIALITY_POLICY}>https://portal-survey.rosenergoatom.ru/confidentiality-policy</Link>.
      </p>
    </section>
  )
}

export default ConfidentialityPolicyPage
