import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import App from './components/App'
import PrivateRoute from './components/PrivateRoute'
import * as routes from './constants/routes'
import ConfidentialityPolicyPage from './pages/ConfidentialityPolicyPage'
import * as serviceWorker from './serviceWorker'
import store from './store/store'
import './styles/index.scss'

const AuthPage = lazy(() => import('./pages/AuthPage'))
// const StubPage = lazy(() => import('./pages/StubPage'))
const SurveysPage = lazy(() => import('./pages/SurveysPage'))

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App>
        <Suspense fallback={false}>
          <Switch>
            <Route path={routes.AUTH} component={AuthPage} />
            <PrivateRoute exact path={routes.MAIN} component={SurveysPage} />
            <PrivateRoute exact path={routes.SURVEYS} component={SurveysPage} />
            <PrivateRoute exact path={routes.SURVEY} component={SurveysPage} />
            <PrivateRoute path={routes.SURVEYS_PASSED} component={SurveysPage} />
            <PrivateRoute path={routes.SURVEY_COMPLETED} component={SurveysPage} />
            <Route path={routes.CONFIDENTIALITY_POLICY} component={ConfidentialityPolicyPage} />
          </Switch>
        </Suspense>
      </App>
    </BrowserRouter>
  </Provider>,

  document.getElementById('root'),
)
serviceWorker.unregister()
